/** @format */

body {
  background: #f1f1f1;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.hide-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.widthCalc {
  width: calc(100% - 240px) !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.text-custom > .CircularProgressbar-text {
  transform: translate(-15px, 5px);
}
.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
  height: 100% !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
